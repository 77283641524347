<template>
  <b-container>
    <b-overlay
      :show="show_loading"
      rounded="lg"
      variant="white"
      :opacity="1"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>Atualizando os dados, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <transition name="slide-fade">
        <b-row v-if="renderPage">
          <b-col
            class="ml-auto"
            md="auto"
            sm="12"
            lg="auto"
            xl="auto"
          >
            <b-card
              class="text-left fix-card"
              title="Detalhes do Usuário"
            >
              <h5>Nome:</h5>
              <p>{{ userData.user_name }} </p>

              <h5>Telefone:</h5>
              <p>{{ phone }} </p>

              <h5>E-mail:</h5>
              <p>{{ userData.email }} </p>

              <h5>Setor:</h5>
              <p>{{ userData.sector }} </p>

              <h5>Gestor: </h5>
              <p>{{ userData.manager }} </p>

              <h5>Perfil: </h5>
              <p>{{ userData.profile }} </p>

              <h5>Perfil do Whatsapp: </h5>
              <p>{{ userData.whatsappProfile }} </p>
            </b-card>
          </b-col>
          <b-col
            class="mr-auto"
            md="6"
            sm="12"
            lg="6"
            xl="6"
          >
            <b-card title="Editar Usuário">
              <b-form
                novalidate
                class="needs-validation"
                @submit.prevent
              >
                <label for="v-name">Nome: </label>
                <b-form-input
                  id="v-name"
                  v-model="userName"
                  placeholder="Informe o Nome"
                />

                <b-form-group
                  class="mt-1 mb-0"
                >
                  <label for="v-name">Telefone: </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Telefone"
                    rules="required|phone"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="phone"
                      v-mask="['(##) #####-####', '(##) ####-####']"
                      placeholder="Digite o telefone do usuário"
                      :state="
                        errors.length > 0 ? false : null
                      "
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <label
                  for="v-setor"
                  class="mt-1"
                >Setor: </label>
                <v-select
                  v-model="selectedSector"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Selecione o Setor"
                  label="title"
                  :options="sectorOptions"
                />
                <label
                  for="v-diretor"
                  class="mt-1"
                >Gestor: </label>
                <v-select
                  v-model="selectedManager"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Selecione o Gestor"
                  label="title"
                  :options="managerOptions"
                />
                <label
                  for="v-profile"
                  class="mt-1"
                >Perfil: </label>
                <v-select
                  v-model="selectedProfile"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  placeholder="Selecione o Perfil"
                  :options="profileOptions"
                />

                <label
                  for="v-profile"
                  class="mt-1"
                >Perfil do Whatsapp: </label>
                <v-select
                  v-model=" whatsappGroupProfileId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  placeholder="Selecione o Perfil"
                  :options="groupsOptions"
                />

                <b-row class="mt-1">
                  <b-col
                    md="12"
                  >
                    <b-button
                      class="w-100"
                      variant="primary"
                      @click="showConfirmCreateUser"
                    >
                      Cadastrar na Plataforma de Crédito
                    </b-button>
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col
                    md="6"
                    sm="6"
                  >
                    <b-button
                      variant="primary"
                      block
                      @click="showConfirm"
                    >Salvar</b-button>
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                  >
                    <b-button
                      variant="primary"
                      :to="{ name: 'usuarios' }"
                      block
                    >Voltar</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </transition>
    </b-overlay>
  </b-container>
</template>

<script>
import {
    BContainer,
    BFormGroup,
    BRow,
    BFormInput,
    BCard,
    BButton,
    BCol,
    BForm,
    BOverlay,
    BIcon,
    BSpinner,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { updateUser, findUser } from '@/service/user/userBackOffice';
import { listProfile } from '@/service/profile/profile';
import { createOsirisUser } from '@/service/credit_platform/users';
import { callLoading } from '@/service/utilities';

import UserAtomService from '@/service/user-atom';

import '@validations';

export default {
    components: {
        vSelect,
        BForm,
        BContainer,
        BRow,
        BFormInput,
        BCard,
        BButton,
        BSpinner,
        BCol,
        BOverlay,
        BIcon,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            show_loading: false,
            processing: false,
            userData: {},
            userName: null,
            selectedSector: null,
            selectedProfile: null,
            selectedManager: null,
            sectorOptions: [
                { title: 'Administração', value: 17 },
                { title: 'Atendimento', value: 5 },
                { title: 'Cadastro', value: 8 },
                { title: 'Cobrança', value: 4 },
                { title: 'Cobrança Soulpay', value: 3 },
                { title: 'Comercial', value: 6 },
                { title: 'Controladoria', value: 11 },
                { title: 'Financeiro', value: 15 },
                { title: 'Financeiro SoulPay', value: 14 },
                { title: 'Jurídico', value: 7 },
                { title: 'Marketing', value: 13 },
                { title: 'Operações', value: 2 },
                { title: 'Operações Soulpay', value: 1 },
                { title: 'Recursos Humanos', value: 12 },
                { title: 'Risco SoulPay', value: 9 },
                { title: 'Risco Via Invest', value: 10 },
                { title: 'TI', value: 16 },
                { title: 'Originação', value: 18 },
                { title: 'Contas a Pagar', value: 19 },
            ],
            managerOptions: [
                { title: 'Diogo Favero', value: 3 },
                { title: 'Jaqueline Gomes', value: 5 },
                { title: 'Karen Ferreira', value: 6 },
                { title: 'Luiz Casante', value: 1 },
                { title: 'Natalia Pereira', value: 7 },
                { title: 'Paulo Castro', value: 2 },
                { title: 'Raquel Amaya', value: 4 },
            ],
            profileOptions: [],
            response_user: null,
            renderPage: true,
            whatsappGroupProfileId: null,
            whatsappProfiles: [],
            phone: '',
        };
    },

    computed: {
        groupsOptions() {
            return this.whatsappProfiles.map(item => ({
                value: item.id,
                title: item.name,
            }));
        },
    },

    async mounted() {
        this.show_loading = true;

        this.profileOptions = await this.listProfile();

        const { data } = await UserAtomService.index();

        this.whatsappProfiles = data.data;

        await this.getUser();
        this.show_loading = false;
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },

    methods: {
        async showConfirmCreateUser() {
            this.$bvModal
                .msgBoxConfirm('Deseja Cadastrar o Usuário na Plataforma de Crédito', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.showConfirmCreateAdminUser();
                    }
                });
        },
        async showConfirmCreateAdminUser() {
            this.$bvModal
                .msgBoxConfirm('Deseja Cadastrar o Usuário como Administrador na Plataforma de Crédito', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    this.createUser(value);
                });
        },
        async createUser(full_access = false) {
            callLoading(true);
            const { status } = await createOsirisUser({
                user_id: this.userData.userId,
                full_access,
            });
            callLoading(false);
            if (status === 201) {
                this.success('Usuário registrado na plataforma de crédito');
            } else if (status === 422) {
                this.error('Usuário já cadastrado na plataforma de crédito');
            } else {
                this.error('Não foi possível regitrar o usuário na plataforma de crédito');
            }
        },
        showConfirm() {
            this.$bvModal
                .msgBoxConfirm('Deseja Salvar as Informações?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.updateUser();
                    }
                });
        },
        async listProfile() {
            const { data, status } = await listProfile();

            if (status !== 200) {
                this.$router.push('error404');
                return;
            }

            const { profiles } = data;

            const profileOptions = profiles.map(profile => ({
                title: profile.profile_name,
                value: profile.id,
            }));

            return profileOptions.sort((a, b) => ((a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)));
        },
        async getUser() {
            const { id } = this.$route.params;
            const response = await findUser(id);

            const { data } = response;

            const {
                phone, whatsapp_group_profile_id, backoffice_sector_id, backoffice_manager_id, backoffice_profile_id, user, backoffice_sector, backoffice_manager, backoffice_profile,
            } = data;
            const { name, email } = user;

            this.userName = name;
            const [selectedSector] = this.sectorOptions.filter(option => option.value === backoffice_sector_id);
            const [selectedManager] = this.managerOptions.filter(option => option.value === backoffice_manager_id);
            const [selectedProfile] = this.profileOptions.filter(option => option.value === backoffice_profile_id);
            const [selectedWhatsappGroup] = this.groupsOptions.filter(option => option.value === whatsapp_group_profile_id);

            const userData = {
                id,
                email,
                user_name: name,
                sector: backoffice_sector.name,
                manager: backoffice_manager.name,
                profile: backoffice_profile.profile_name,
                userId: data.user_id,
                phone: data.phone,
                whatsappProfile: selectedWhatsappGroup.title,
            };

            this.userData = userData;
            this.response_user = response;

            this.selectedSector = selectedSector;
            this.selectedManager = selectedManager;
            this.selectedProfile = selectedProfile;
            this.phone = phone;
            this.whatsappGroupProfileId = selectedWhatsappGroup;
        },
        async updateUser() {
            this.show_loading = true;
            const param = this.$route.params.id;

            const response = this.response_user;

            const userName = this.userName === null ? response.user.name : this.userName;

            const { status } = await updateUser({
                name: userName,
                sector: this.selectedSector.value,
                manager: this.selectedManager.value,
                profile: this.selectedProfile.value,
                whatsAppProfile: this.whatsappGroupProfileId.value,
                phone: `55${this.phone.replace(/[^\w\s]/g, '').replace(' ', '')}`,
            }, param);
            if (status === 200) {
                await this.getUser();
                this.cleanForm();
                this.show_loading = false;
                this.success();
            } else {
                this.show_loading = false;
                this.error('Falha na Atualização');
            }
        },
        //
        success(msg = 'Usuário Editado com Sucesso!') {
            this.$swal({
                icon: 'success',

                html: `<span> ${msg} </span> `,
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },
        error(msg) {
            this.$swal({
                icon: 'error',

                html: `<span> ${msg} </span> `,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },
        cleanForm() {
            this.selectedSector = null;
            this.selectedManager = null;
            this.selectedProfile = null;
        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/new-client.scss";
</style>
